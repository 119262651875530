.wrapper {
    max-width: 80%;
    margin: 20px auto 80px;
    display: grid;
}

.formItem {
    background-color: red;
    padding: 4px;
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,0.65);
    margin: 5px 0;
}

.formItem input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 5px 10px;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    width: 50%;
}

.formItem input:focus {
    outline: none !important;
}

@media only screen and (min-width: 768px){
    .wrapper {
        max-width: 63%;
    }
}