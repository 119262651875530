.cbtn {
	background-color: var(--color-orange);
	border: none;
	color: var(--color-white);
	text-transform: uppercase;
	font-weight: bold;
	border-radius: var(--border-radius);
	display: inline-block;
    padding: 6px 15px 5px 15px;
	position: relative;
	font-size: 12px;
	width: 80px;
	line-height: 1.6;
}

.cbtnIcon {
	position: absolute;
	top: 7px;
	right: 10px;
	display: block;
	margin-left: 15px;
}

@media only screen and (max-width: 410px) {
	.cbtnIcon {
		display: none;
	}
}

@media only screen and (min-width: 410px) and (max-width: 800px) {
	.cbtn {
		width: 108px;
        font-size: 14px;
	}

    .cbtnIcon {
        font-size: 14px;
    }
}

@media only screen and (min-width: 801px) {
	.cbtn {
		font-size: var(--font-size);
		width: 132px;
	}
}
