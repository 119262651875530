.mnavwrap {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border: 1px solid lightgray;
    border-radius: var(--border-radius-big);
    width: 90%;
    height: 80px;
    background-color: var(--color-white);
    max-width: 420px;
    place-items: revert;
    text-align: center;
    box-shadow: var(--shadow-hover);
}

.bottomBorder {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    background-color: rgb(71, 70, 70);
    width: 30%;
    box-shadow: var(--shadow-hover);
}

@media only screen and (min-width: 900px){
    .mnavwrap, .bottomBorder {
        display: none;
    }
}

.mnavlink {
    display: grid;
    text-decoration: none;
    color: gray;
}

.mnavlinkActive {
    display: grid;
    text-decoration: underline;
    background-color: var(--color-orange);
    color: var(--color-white);
    margin: 5px;
    border-radius: var(--border-radius);
    font-weight: bold;
}

.mnavlink:hover {
    color: var(--color-orange);
}

.mnavlinkIcon {
    position: relative;
    top: 15px;
}

.plusWrapper {
    background-color: var(--color-orange);
    width: 40px;
    height: 40px;
    color: white;
    display: grid;
    place-items: center;border-radius: 50%;
    position: relative;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: var(--shadow);
}

.plusWrapper:hover {
    width: 50px;
    height: 50px;
    box-shadow: var(--shadow-hover);
}

.plusWrapper a {
    color: var(--color-white);
}

.mnavlinkDesc {
    font-size: 14px;
}