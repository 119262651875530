* {
  margin: 0px;
  padding: 0px;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --size-sm: "620px";
  --size-md: "768px";

  --color-white: #fff;
  --color-orange: #f78a0f;
  --border-radius: 4px;
  --border-radius-big: 8px;

  --body-font: 16px;

  --shadow: 0 4px 6px -2px rgba(0,0,0,0.05),0 10px 15px -3px rgba(0,0,0,0.1);
  --shadow-hover: 0 4px 10px -2px rgba(0,0,0,0.1),0 12px 20px -3px rgba(0,0,0,0.2);

}

body {
    font-family: 'Nexa Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--body-font);
}

p {
  line-height: 1.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
}

html, body, #app, #app>div {
  height: 100%
}

p {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
            font-family: 'Nexa Light', sans-serif;
}

@media screen and (min-width: 710px){
  .row-grid {
    display: grid;
    grid-template-columns: 1fr 315px 1fr;
    padding: 21px 0 0;
  }
}

@media screen and (max-width: 710px){
  .home-img {
    max-width: 50%;
    margin: 0 auto;
  }

  .paddingBot {
    padding-bottom: 80px;
  }
}

.text-center {
  text-align: center;
  max-width: 80%;
  margin: 20px auto;
}
