.mmwrap {
    width: 100%;
    max-width: 974px;
    background-color: rgba(255,255,255,0.7);
    border-radius: var(--border-radius);
}

@media only screen and (max-width: 900px){
    .mmwrap {
        display: none;
    }
}

.mmlist {    
    list-style: none;
    align-items: center;
    display: flex;
    justify-items: center;
    justify-content: center;
}

.mmlistItem {
    text-decoration: none;
    color: black;
    display: block;
    text-align: center;
    min-width: 100px;
    padding: 21px 0;
}

.mmlistItem:hover,
.mmlistIemActive {
    text-align: center;
    display: block;
    color: red;
    min-width: 100px;
    text-decoration: underline;
    padding: 21px 0;
}

@media only screen and (min-width: 1074px) {
    .mmlistItem,
    .mmlistItem:hover,
    .mmlistItemActive {
        padding: 35px 0;
    }
}