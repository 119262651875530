.dnavWrapper {
    position: relative;
}

.dnavContainer {
    margin: 20px auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 50%;
}

@media only screen and (max-width: 768px){
    .dnavContainer {
        margin: 0 auto;
    }
}