.infobtn {
    background: rgb(196,42,56);
    background: linear-gradient(0deg, rgba(196,42,56,1) 0%, rgba(245,130,115,1) 71%);
    border-radius: var(--border-radius);
    color: var(--color-white);
    padding: 8px 10px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
}

.right {
    width: 100%;
    text-align: right;
}