 .grid4 {
  display: grid;
  padding: 40px 20px 0;
  text-align: center;
} 

.grid4 ul li {
  padding: 5px 0;
}

.grid4 ul li::before {
  content: " ";
  padding-right: 3px;
}

@media screen and (min-width: 803px){
    .grid4 {
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
}

@media only screen and (max-width: 803px) and (min-width: 560px)  {
    .grid4 {
      max-width: 70%;
      margin: 0 auto;
        grid-template-columns: repeat(2,1fr);
    }
}

.visited::before {
  content: "> ";
}

.visited {
  text-decoration: none;
  color: var(--color-orange);
}

.link {
  color: var(--color-orange);
}