.container {
    max-width: 55%;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.container img {
    mix-blend-mode: multiply;
}

.textWrap {
    position: absolute;
    top: 80px;
    left: 10px;
}

.textWrap h1 {
    margin-bottom: 10px;
}

.textWrap li {
    padding: 3px 0;
    max-width: 230px;
    line-height: 1.2;
}

@media screen and (max-width: 1000px) {
    .textWrap {
        position: relative;
        text-align: center;
    }

    .container img {
        margin-bottom: -40px;
    }
}