.gridItem {
    padding: 10px 30px;
}

.gridItem ul {
    margin-top: 20px;
}

.gridItem ul li::before {
    content: " ";
    padding-right: 3px;
}

.gridItem ul li {
    padding: 5px 0;
}