.logoWrapper {
    background-color: rgba(0,0,0,0.15);
}

.logo {
    margin: 20px 0;
    width: 70%;
    position: relative;
    left: -5%;
}

@media only screen and (min-width: 1200px){
    .logo {
        left: -7%;
    }
}