.paperWrapper {
    width: 100%;
    background-image: url("../assets/mainbg.jpg");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    border-top: 1px solid black;
    padding-bottom: 110px;
}

.paper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 10px 20px;
    background-color: rgba(255,255,255,0.5);
    padding-bottom: 20px;
}