.wrapper {
    background-color: rgba(0, 0, 0, 0.15);
    padding: 0 20px 20px;
}

.container {
    background-color: rgba(0,0,0,0.65);
    border-radius: var(--border-radius);
    padding: 5px;
    position: relative;
    display: flex;
}

@media only screen and (max-width: 900px){
    .container, .wrapper {
        display: none;
    }
}

.input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: var(--color-white);
    padding: 5px 10px;
}

.label {
    color: var(--color-white);
}

.label:nth-child(2) {
    margin-left: 40px;
}

.input:focus {
    outline: none !important;
}

.submit {
    border: 1px solid black;
    background-color: var(--color-orange);
    border-radius: var(--border-radius);
    color: var(--color-white);
    padding: 8px 13px;
    position: absolute;
    right: -50px;
    bottom: -15px;
    font-size: 1.1em;
    cursor: pointer;
}